import React, {createContext, useCallback, useEffect, useRef, useState} from 'react';
import classes from './Goods.module.css';
import DisplaySingleItem from './DisplaySingleItem/DisplaySingleItem';
import {Link} from 'react-router-dom';
import Like from '../Likes/Likes';
import CartButton from './MainAddElement/CartButton';
import  ChooseProduct from './ChooseProduct/ChooseProduct';

export const AddModalContext = createContext();

const DisplayItems = ({

                          data,
                          likes = false,
                        isHorizontal = false, 
                        setIsFetchMoreCallback = () => {}, 
                        hasMore = false, 
                        cartFormat = "DOUBLE",
                        onClickCallback

                      }) => {


    const [isModalActive, setModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [modalContent, setModalContent] = useState('');

    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const observer = useRef()

    //create a link to track the last item
    const lastItemRef = useCallback(node => {
       
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting && hasMore) {
                
                setIsFetchMoreCallback(true)
                
            }
        })
        if (node) observer.current.observe(node)
    }, [hasMore])


    const display_goods = (data.item.length>0) && data?.item?.map((item, index)  => {
        let isItemLiked
        if (likes){
             isItemLiked = true
        } else {
             isItemLiked = item.users_likes.length > 0
        }
        
    const goodsClass = cartFormat === "DOUBLE" ? classes.goodsItemDouble : classes.goodsItemSingle


           
           return (
           
           <div className = {goodsClass} 
                        key = {item.id} 
                        onClick = {onClickCallback}
                        ref={(hasMore && index === data?.item.length - 1) ? lastItemRef : null}
                        >

                <Link to = {`/product/${item.id}`} style = {{width: "100%"}}>
                    <DisplaySingleItem item = {item} isLiked = {isItemLiked} cartFormat = {cartFormat}/>
                </Link>

                <div className = {classes.likeContainer}>
                    <Like ItemID = {item.id} doWeHaveLike = {isItemLiked}/>
                </div>
                <CartButton itemId = {item.id}
                    setModalActive={setModalActive}
                    setCurrentItem={setCurrentItem}
                />
            </div>)

        }
                      )


   
    return (
        <>
            {
        isHorizontal
        ? <div className={classes.ContainerHorizontal}>{display_goods}</div>
        : (cartFormat === "DOUBLE"

            ? <div className={classes.ContainerDouble}>{display_goods}</div>
            : <div className={classes.ContainerSingle   }>{display_goods}</div>)
            }

            {isModalActive &&
            <ChooseProduct isModalActive = {isModalActive}

                                 setModalActive = {setModalActive}
                                 modalContent = {modalContent}
                                 setModalContent = {setModalContent}
                                 
                                currentItem = {currentItem}

                                /> 
                                
                                }

        </>
    )
}


export default React.memo(DisplayItems)
