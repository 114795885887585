import React, {useCallback, useContext, useEffect, useState} from 'react';
import DisplayItems from './DisplayItems';
import {useQuery} from '@apollo/client';
import {TelegramContext} from '../../App';

const LoadItemsData = ({limit, query, variables, keyName, aggregateKeyName, isHorizontal, onClickCallback}) => {


    /*LoadItemsData  :  get query - query for load data,
                         variables - variables for query,
                        keyName - key name for data from query,?
                         aggregateKeyName - key name for aggregate data from query,?
                          isHorizontal - for  horizontal display items,
                           onClickCallback - callback for reset search value,

        load data from query, define fetchMore function,
        and return DisplayItems with data from query
        and then send it to DisplayItems component
        */
    

    // console.count('loaditems')
    



    const {userID} = useContext(TelegramContext);
    const [hasMore, setHasMore] = useState(true);
    const [isFetchMore, setIsFetchMore] = useState(false);
    const setIsFetchMoreCallback = useCallback(setIsFetchMore, [])



    const {loading, error, data, fetchMore} = useQuery(query, {
        variables: variables, fetchPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (isFetchMore && data[keyName].length < data[aggregateKeyName].aggregate.count) {


            fetchMore({
                variables: {userID, offset: data[keyName].length, limit},
                updateQuery: (prevResult, {fetchMoreResult}) => {
                    if (!fetchMoreResult) return prevResult;
                    return {
                        ...prevResult, [keyName]: [...prevResult[keyName], ...fetchMoreResult[keyName]],
                    };
                },
            })
            setIsFetchMore(false)
        }

    }, [isFetchMore, data])

    useEffect(() => {
        data && data[keyName] && data[keyName].length < data[aggregateKeyName].aggregate.count ? setHasMore(true) : setHasMore(false)
    }, [data])

    // console.log('loaditems data', data)

    if (loading) {
        return <div>{"loading..."}</div>;
    }
    if (error) {
        console.error('votzhe ebstvo', error);
    }


    if (data && data[keyName] && data[keyName].length > 0) {

        
        return <DisplayItems data = {{item: data[keyName], users_likes: data.users_likes}}
                             setIsFetchMoreCallback = {setIsFetchMoreCallback}
                             hasMore = {hasMore}
                             isHorizontal = {isHorizontal}
                             onClickCallback = {onClickCallback}
        />
    } else {
        return null
    }


}


export default React.memo(LoadItemsData)
