import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache} from '@apollo/client';
import Cookies from 'js-cookie';
import { setContext } from '@apollo/client/link/context';



import setCookie from "./utils/cookies";

export const cache = new InMemoryCache();



// async function fetchAccessToken(url) {
//     try {
//         const response = await fetch(url, {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' }
//         });
        
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         return data.access_token; // Предполагаем, что токен находится в свойстве access_token
//     } catch (error) {
//         console.error("Error fetching the access token: ", error);
//     }
// }








const { userFirstName, userID,  fromTg } = await setCookie();
// console.log(userFirstName, userID, newToken);




let authLink = '';

// Cookies.remove('token');

if (fromTg) {
    
     authLink = setContext((_, { headers }) => {
        // const newToken = Cookies.get('token');
        console.log('x - hadsura admin secrer', process.env.REACT_APP_HASURA_KEY)

        return {
            headers: {
                ...headers,

                // authorization: `Bearer ${newToken}`,
                // authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiZ3Vlc3QiLCJ1c2VycyJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJndWVzdCJ9fQ.fxrYceDJpJ9aMd36HPD6lb5vP3CmL9PkUq9dpHRA14U' ,
                'x-hasura-admin-secret': process.env.REACT_APP_HASURA_KEY,
                
            }
        };
    });
} else {

    authLink = setContext((_, { headers }) => {
    const newToken = Cookies.get('token');
    console.log('newToken: in auth', newToken)
    // console.log('newToken: in auth', newToken)

    return {
        headers: {
            ...headers,

            authorization: `Bearer ${newToken}`,
            // authorizajs-cookietion: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiZ3Vlc3QiLCJ1c2VycyJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJndWVzdCJ9fQ.fxrYceDJpJ9aMd36HPD6lb5vP3CmL9PkUq9dpHRA14U' ,
            // 'x-hasura-admin-secret': process.env.REACT_APP_HASURA_KEY,
            
        }
    };
});


}











const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_URl,
});




const createApolloClient = () =>{ return  new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    credentials: 'include'
});
}
  


// const createApolloClient = () => {
//     return new ApolloClient({
//         link: new HttpLink({
//             uri: process.env.REACT_APP_HASURA_URl,
//             headers: {
                
//                 authorization:  `Bearer ${token}` ,
            

                // 'x-hasura-admin-secret': process.env.REACT_APP_HASURA_KEY,
                // 'x-hasura-role': 'guest',
//             
//         }),
//         cache: cache,
//     });
// };


// const httpLink = new HttpLink({
//     uri: "https://hasura.jbspace.xyz/v1/graphql",
// });

// const jwt = require('jsonwebtoken');

// const JWT_SECRET = 'your_jwt_secret';

// const guestToken = (req, res) => {
//         const token = jwt.sign(
//             {
//               "https://hasura.io/jwt/claims": {
//                 "x-hasura-allowed-roles": ["guest"],
//                 "x-hasura-default-role": "guest",
//                 "x-hasura-user-id": "0"  // Assuming '0' or another identifier for guests
//               }
//             },
//             JWT_SECRET,
//             {
//               expiresIn: '1h'
//             }
//           );
      
//           res.json({ token });
// };




export const client = createApolloClient();

//console.log('client.cache', client.cache)


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ApolloProvider client = {client}>
        <App userId = {userID}  userName = {userFirstName}/>
    </ApolloProvider>
);


